<template>
    <div class="page_content">
        <!-- <a-spin size="large" v-show="loading" /> -->
        <div class="page_edit_content">
            <Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit" @handleChange="handleChange" @get_select_value="get_select_value">
                <div slot="customer_name">
                    {{detail.customer_name}}
                </div>
                <div slot="type_name">
                    {{detail.type_name}}
                </div>
                
                <div slot="give_user">
                    <span v-if="detail.give_type == 'user'">{{approval_data.username}}</span>
                    <a @click="add_customer()" v-else>
                        <a-icon v-if="!select_customer.name" type="plus-circle" />
                        <span style="padding-left: 5px">{{
                            select_customer.name
                                ? select_customer.name
                                : "配送人"
                        }}</span>
                    </a>
                    <a-input type="hidden" name="give_user" v-decorator="['give_user', give_user]" />
                </div>
                <div slot="time_limit">
                    {{detail.time_limit}}
                </div>
                <div slot="week">
                    <a @click="add_week()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加发放周</span>
                    </a>
                    <a-input type="hidden" name="week_data" v-decorator="['week_data', week_old]" />
                    <div>
                        <ul>
                            <li v-for="(item, index) in week_data" :key="index">
                                <div>
                                    <span>{{ item}}</span>
                                </div>
                                <a @click="del_week(item)">删除</a>
                            </li>
                        </ul>
                    </div>
                </div>             
                <div slot="contract_name">
                    {{detail.contract_name}}
                </div>
                <div slot="goods_data">
                    <a @click="add_goods()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加物料</span>
                    </a>
                    <a-input type="hidden" name="goods_data" v-decorator="['goods_data', goods_datas]" />
                    <div>
                        <ul>
                            <li v-for="(item, index) in goods_datas" :key="index">
                                <div>
                                    <span>品项：{{ item.goods_title }}</span>
                                    <span>数量：{{ item.num }}</span>
                                    <span>时间：{{item.goods_date}}</span>
                                    <span>投放位置：{{item.name}}</span>
                                </div>
                                <a @click="del_contact(item)">删除</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Form>
        </div>

        <!-- <SelectUser @submit="submit" :visible.sync="visible"></SelectUser> -->
        <SelectCustomer @submit="select_customer_submit" :visible.sync="pid_visible"></SelectCustomer>
        <EditPop @handleSubmit="contact_submit" :form_data="form_data_contact" :visible.sync="goods_visible"></EditPop>
        <EditPop @handleSubmit="week_submit" :form_data="form_data_week" :visible.sync="week_visible"></EditPop>
    </div>
</template>

<script>
    import { constLaunchDetails ,getGoods ,getType ,editLaunch, getSuperior} from '@/api/cost'
    import Form from "@/components/Form";
    import SelectCustomer from "@/components/SelectCustomer";
    import EditPop from "@/components/EditPop";

    export default {
        components: {
            Form,
            EditPop,
            SelectCustomer,
        },
        data() {
            return {
                give_user_id:'',
                approval_data:{},
                detail:{},
                give_user:{},
                goods_datas:[],
                goods_old:[],
                list: [],
                select_customer: [],
                weeks_datas:[],
                week_data:[],
                week_old:[],
                loading: true,
                visible: false,
                pid_visible: false,
                goods_visible:false,
                week_visible:false,
                form_data_week: {
                    title: "添加投放周",
                    list: [{
                        type: "select",
                        name: 'week_value',
                        title: "投放周",
                        options: {
                            rules: [{
                                required: true,
                                message: "请选择投放周"
                            }]
                        },
                        list: []
                    }]
                },
                form_data_contact: {
                    title: "添加物料",
                    list: [{
                            type: "select",
                            name: "goods_id",
                            title: "品项",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择品项"
                                }]
                            },
                            list: []
                        },
                        {
                            type: "text",
                            name: "num",
                            title: "数量",
                            placeholder: "数量",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写数量"
                                }]
                            }
                        },
                        {
                            type: "select",
                            name: "goods_date",
                            title: "时间",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择投放时间"
                                }]
                            },
                            list: [
                                {
                                    key: "月",
                                    value: "月"
                                },
                                {
                                    key: "季度",
                                    value: "季度"
                                },
                                {
                                    key: "半年",
                                    value: "半年"
                                },
                                {
                                    key: "一年",
                                    value: "一年"
                                },
                            ]
                        },
                        {
                            type: "select",
                            name: "type_id",
                            title: "投放位置",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择投放位置"
                                }]
                            },
                            list: []
                        },
                    ]
                },
                form_data: {
                    title: "基本信息",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "text",
                            name: "id",
                            hidden: true,
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "slot",
                            name: "customer_name",
                            title: "申请客户",
                        },
                        {
                            type: "slot",
                            name: "type_name",
                            title: "投放类型",
                        },
                        {
                            type: "select",
                            name: "give_type",
                            title: "配送方式",
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择配送方式"
                                }],
                                initialValue: ""
                            },
                            list: [
                                {
                                    key: "user",
                                    value: "业务员配送"
                                },
                                {
                                    key: "customer",
                                    value: "经销商配送"
                                }
                            ]
                        },                    
                        {
                            type: "slot",
                            name: "give_user",
                            title: "配送人",
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择配送人"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "slot",
                            name: "time_limit",
                            title: "投放期限",
                        },
                        {
                            type: "slot",
                            name: "week",
                            title: "发放周",
                            mode: "default",
                            options: {
                                initialValue: ""
                            },
                            list: []
                        },
                        {
                            type: "text",
                            name: "desc",
                            title: "申请事由",
					        options: {
					            initialValue: ""
					        },
                        },
                        {
                            type: "slot",
                            name: "contract_name",
                            title: "关联合约",
                        },
                        {
                            type: "slot",
                            name: "goods_data",
                            title: "物料",
                            list:[]
                        },
                        {
                            type: "text",
                            name: "cash",
                            title: "现金",
					        options: {
					            initialValue: ""
					        },
                        },
                    ]
                },
            }
        },
        async created() {
              this.getDetail(); 
        },
        methods: {
            getDetail() {
                constLaunchDetails({
                    data: {
                        id: this.$route.query.id,
                    }
                }).then(res => {
                    this.approval_data = res.data.approval_data
                    this.detail = res.data.launch_detail
                    this.goods_datas = this.detail.goods_data
                    this.weeks_datas = this.detail.week_list
                    this.week_old = this.detail.launch_week.split(',')
                    let week_new = [];
                    this.week_old.forEach(item=>{
                        var wk = item.split('-')
                        var wek = wk[0] + '-' + wk[1] + '第' + wk[2] + '周'
                        week_new.push(wek)
                    })
                    this.week_data = week_new
                    this.form_data.list.forEach(item => {
                        if (item.type == 'slot') {
                            if (item.name == "give_user") {
                                if (this.detail.give_name&&this.detail.give_type == 'customer') {
                                    this.select_customer = this.detail.give_name;
                                    this.$refs.form.setFieldsValue({
                                        give_user: this.select_customer.id
                                    });
                                    this.give_user_id = this.select_customer.id
                                } else {
                                    this.select_customer = [];
                                    this.$refs.form.setFieldsValue({
                                        give_user: ""
                                    });
                                    this.give_user_id = this.approval_data.user_id
                                }
                            }
                        } else {
                            item.options.initialValue = this.detail[item.name];
                        }
                    })
                })
            },
            add_customer() {
                this.pid_visible = true;
            },
            select_customer_submit({
                data
            }) {
                this.pid_visible = false;
                this.select_customer = data;
                this.$refs.form.setFieldsValue({
                    give_user: this.select_customer.id
                });
                this.give_user_id = this.select_customer.id
            },
            get_goods() {
                getGoods({
                    data: {
                        type: 'launch'
                    }
                }).then(res => {
                    let goods = [];
                    this.goods_old = res.data.list
                    res.data.list.forEach(item => {
                        var obj = {key:item.id,value:item.goods_title}
                        goods.push(obj);
                    })
                    this.form_data_contact.list[0]['list'] = goods
                })
            },
            get_type() {
                getType().then(res => {
                    var type = []
                    res.data.list.forEach(item => {
                        var obj = {key:item.id,value:item.name}
                        type.push(obj);
                    })
                    this.form_data_contact.list[3]['list'] = type
                })
            },
            add_goods() {
                this.goods_visible = true;
                this.get_goods();
                this.get_type();
            },
            contact_submit(e) {
                let value = e.values;
                var goods = {}
                this.goods_old.forEach(item=>{
                    if (item.id == value.goods_id) {
                        return goods = item
                    }
                })
                var new_obj = Object.assign(value,goods);
                var type_list = this.form_data_contact.list[3]['list']
                type_list.forEach(item=>{
                    if (item.key == new_obj.type_id) {
                        new_obj.name = item.value
                    }
                })
                this.goods_datas.push(new_obj)
                this.goods_visible = false;
            },
            del_contact(params) {
                this.goods_datas.forEach((item, index) => {
                    if (params == item) {
                        this.goods_datas = this.goods_datas.slice(0, index).concat(
                                this.goods_datas.slice(index + 1, this.goods_datas.length)
                            );
                    }
                });
            },
            del_week(params) {
                this.week_data.forEach((item, index) => {
                    if (params == item) {
                        this.week_data = this.week_data.slice(0, index).concat(
                                this.week_data.slice(index + 1, this.week_data.length)
                            );
                        this.week_old = this.week_old.slice(0, index).concat(
                                this.week_old.slice(index + 1, this.week_old.length)
                            );
                    }
                });
            },
            add_week() {
                this.week_visible = true
                let weeks = [];
                this.weeks_datas.forEach(item => {
                    var wk = item.split('-')
                    var wek = wk[0] + '-' + wk[1] + '第' + wk[2] + '周'
                    var wk = {key:item,value:wek}
                    weeks.push(wk)
                })
                this.form_data_week.list[0]['list'] = weeks
            },
            week_submit(e) {
                let wk = e.values.week_value.split('-');
                var wek = wk[0] + '-' + wk[1] + '第' + wk[2] + '周'
                this.week_data.push(wek);
                this.week_old.push(e.values.week_value);       
                this.week_visible = false
            },
            handleSubmit(e) {
                // if (e.give_type == 'user') {
                //     give_user_id = this.approval_data.user_id
                // } else {
                //     give_user_id = this.give_user.id
                // }
                editLaunch({
                    data:{
                        ...e.values,
                        give_user:this.give_user_id,
                        id:this.$route.query.id,
                        goods_data:this.goods_datas,
                        week_data:this.week_old
                    }
                }).then(res=> {
                    this.$router.go(-1);
                })
            },
            handleChange(e) {
                
            },
            get_select_value(e) {
                this.detail.give_type = e
                if (e == 'user') {
                    this.give_user_id = this.approval_data.user_id
                } else {
                    this.getCustomer();
                }
            },
            getCustomer() {
                getSuperior({
                    data: {
                        customer_id:this.detail.customer_id
                    }
                }).then(res => {
                    if (res.data.success) {
                        this.give_user = res.data.superior
                        this.select_customer = res.data.superior
                        this.give_user_id = res.data.superior.id
                    } else {
                        this.give_user = []
                        this.select_customer = []
                    }
                })
            }

        },
    };
</script>
<style lang="less" scoped>
    .map_select {
        position: relative;

        .serach_input {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    ul {
        padding: 0;
    }

    li {
        display: block;
        list-style: none;
        display: flex;

        span {
            padding-right: 10px;

            display: inline-block;
        }
    }

    .avatar-uploader>.ant-upload {
        width: 128px;
        height: 128px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }

    .distributor {
        width: 60%;
        margin-left: 1.5%;
        padding: 5px 10px;
        padding-bottom: 10px;
    }

    .distributor_box {
		background-color: rgba(250, 250, 250, 1);
		margin-left: 14%;
		padding-bottom: 20px;
		.title{
			margin-left: 24px;
		}
		/deep/.ant-col-5{
			width: 7%;
		}
    }
</style>
